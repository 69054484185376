import React from "react";
import styled from "styled-components";

export default function AboutUs() {
  return (
    <StyledWrapper>
      <div className="container">
        <h1>《Vennchat IM Privacy Policy》</h1>
        <p>Last version date：May 2023</p>
        <p>
          Welcome to our App (Vennchat). We are committed to protecting the
          privacy of our users and their personal information. This privacy
          policy (the "Policy") explains how we collect, use, and disclose
          information about our users and their activities on the App.
        </p>
        <p>
          Information We Collect: <br />
          Account information:You register a phone number when you create a
          Vennchat account. Phone numbers are used to provide our Services to
          you and other Vennchat users. You may optionally add other information
          to your account, such as a profile name and profile picture. This
          information is end-to-end encrypted. We may collect those personal
          information to our server,such as profile name and profile picture.
          Location data: We may collect information about your location through
          your device's GPS or other location-tracking technology. Browsing
          history: We may collect information about the pages, posts, and other
          content that you view on the App.
        </p>
        <p>
          Use of Information： <br />
          Personalizing your experience: We use your information to provide you
          with a personalized experience on the App, Display the profile name
          and profile picture and other information to others.such as suggesting
          content or features that may be of interest to you. You can manage
          your personal information in Vennchat’s application Settings. For
          example, you can update your profile information . Targeted
          advertising: We may use your information to serve you targeted ads on
          the App and on other websites and apps. Improving the App: We may use
          your information to improve the App and develop new features.
        </p>
        <p>
          The types of personal information collected by the functions of the
          products or services we offer, respectively, and the rules for
          collecting and using personal information (e.g., the purpose and
          manner of collecting and using personal information) are as follows:
        </p>
        <p>
          1, when you sign up for the Vennchat service, we will collect your
          cell phone number for creating an account and using social features.
          You can choose to add other information to your account, such as
          profile name and profile picture, the name and picture you add, will
          be stored in our server, because the storage is necessary to achieve
          this function, we will store in an encrypted way, you can also remove
          this information at any time. We will only use your relevant
          information within this application. During the registration and use
          of the service, if you provide the following information to complete
          your profile: age, city, occupation, school, it will help us to
          provide you with a better service and experience, and we can provide
          you with personalized services related to the above information.
          However, if you do not provide this information, only will prevent you
          from using or participating in the personalized services associated
          with this, but will not affect the use of Vennchat products or
          services of the basic functions. You provide the above information
          will continue to authorize us to use during your use of the Service.
          When you voluntarily delete your account, we will delete your personal
          information in accordance with the applicable laws and regulations.
        </p>
        <p>
          2、When you use groups, discovery, my times and other information
          posting functions to post information publicly, including posting
          pictures/videos/publishing comments, you need to open photo album
          permission, camera permission, microphone permission, location
          permission to take photos or videos or voice interaction or show
          location accordingly. Please note that the information you post
          publicly may contain personal information or even sensitive personal
          information about you or others, so please consider more carefully if
          you choose to upload images containing personal information when
          posting your news. The dynamic photos, videos, comments, likes, etc.
          that you upload are stored on our servers, as storage is necessary to
          enable this functionality. We store them in an encrypted manner, and
          you can delete them at any time. We will not make such dynamic
          information available to the public or use it for purposes other than
          this feature, unless you choose to do so or comply with relevant laws
          and regulations.
        </p>
        <p>
          Sharing of Information: <br />
          Third-party service providers: We may share your information with
          third-party service providers who assist us in operating the App, such
          as hosting providers and analytics companies. Compliance with laws: We
          may disclose your information to government or law enforcement
          agencies if required by law or if we believe it is necessary to
          protect the safety of our users or the general public.
        </p>
        <p>
          Data Retention: <br />
          We retain your information for as long as necessary to provide the
          App's services and to comply with legal and regulatory requirements.
        </p>
        <p>
          Your Rights: <br />
          Access and control of your information: You have the right to access
          and control your personal information on the App, including the right
          to update, correct, or delete your account. Opt-out of targeted
          advertising: You may opt-out of receiving targeted ads on the App at
          any time.
        </p>
        <p>
          Changes to this Policy: <br />
          We may update this Policy from time to time. We will notify you of any
          changes by posting the new Policy on the App and updating the
          "effective date" at the top of this page.
        </p>

        <p>
          Contact Us: <br />
          If you have any questions about this Policy or the App's privacy
          practices, please contact us at info@richlink.ca
        </p>

        <h1>《Vennchat IM Terms of Service》</h1>
        <p>Last version date：January 2023</p>

        <p>
          1) Introduction: This Terms of service is a legal agreement between
          user and Richlink Technologies Inc. for the use of the social media
          app (“Richlink IM”). By accessing, installing or using the Richlink IM
          App, you are agreeing to be bound by the terms and conditions of this
          Terms of service. If you do not agree to the terms of this Terms of
          service, do not install or use the App.
        </p>

        <p>
          2) License Grant: Richlink Technologies Inc. grants you a
          non-transferable, non-exclusive license to download, install, and use
          the Richlink IM App on a single mobile device that you own or control,
          solely for your personal use.
        </p>

        <p>
          3) Restrictions on Use: You may not copy, distribute, sell or
          otherwise transfer the App or any portion thereof. You may not modify,
          reverse engineer, decompile or create derivative works based on the
          App or any portion thereof. You may not use the Richlink IM App for
          any illegal or unauthorized purpose.
        </p>

        <p>
          4) Intellectual Property Rights: The Richlink IM App and all
          intellectual property rights related to it, including but not limited
          to trademarks, patents, and copyrights, are owned by Richlink
          Technologies Inc.. You do not acquire any ownership rights in the App
          as a result of using it.
        </p>

        <p>
          5) User Content: You are solely responsible for any content,
          information or data that you post, upload, or otherwise make available
          through the Richlink IM App (“User Content”).You represent and warrant
          that you own or have the necessary rights to use and authorize
          Richlink Technologies Inc. to use all User Content that you post,
          upload, or otherwise make available through the Richlink IM App.
        </p>

        <p>
          6) Disclaimer of Warranties: The Richlink IM App is provided “as is”
          without warranty of any kind, either express or implied, including but
          not limited to the implied warranties of merchantability and fitness
          for a particular purpose. Richlink Technologies Inc. does not
          guarantee that the Richlink IM App will meet your requirements or that
          its operation will be uninterrupted or error-free.
        </p>

        <p>
          7) Limitation of Liability: Richlink Technologies Inc. shall not be
          liable for any damages, including but not limited to lost profits or
          data, arising from the use of the Richlink IM App.
        </p>

        <p>
          8) Termination: Richlink Technologies Inc. may terminate this Terms of
          service at any time and for any reason, with or without notice to you.
          Upon termination, you must immediately stop using the App and delete
          it from your mobile device.
        </p>

        <p>
          9) Governing Law: This Terms of service shall be governed by and
          construed in accordance with the laws of the state in which Richlink
          Technologies Inc. is located.
        </p>

        <p>
          10) Entire Agreement: This Terms of service constitutes the entire
          agreement between you and Richlink Technologies Inc. with respect to
          the use of the Richlink IM App and supersedes all prior agreements,
          understandings, and negotiations, whether oral or written.
        </p>

        <p>
          11) Acceptance: By accessing, installing or using the Richlink IM App,
          you are indicating your acceptance of the terms and conditions of this
          Terms of service.
        </p>

        <p>
          If you have any questions about this Terms of service, please contact
          us at info@richlink.ca
        </p>
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  background-color: black;
  padding: 30px;
  min-height: 100vh;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  h1 {
    font-size: 26px;
    padding: 30px 0px;
  }
  a {
    color: #d995fa;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
    padding: 30px 0px;
  }
`;
