import React from "react";
import Layout from "../../components/Layout";
import { Container, Row, Col, Image } from "react-bootstrap";
import styled from "styled-components";

export default function HardwareSoftware() {
  return (
    <Layout>
      <Container>
        <Row className="py-3">
          <Col md={6} sm={12}>
            <h1 className="m-3">
              Hardware {`&`} <br />
              Software
            </h1>
            <div className="d-flex flex-row justify-content-between align-items-start text-center px-2 px-md-5">
              <div>
                <Image
                  className="my-4"
                  src="./images/hardware-software/hs1.png"
                  width={80}
                  alt="icon"
                />
                <p>
                  Enterprise <br /> Storage
                </p>
              </div>
              <div>
                <Image
                  className="my-4"
                  src="./images/hardware-software/hs2.png"
                  width={60}
                  alt="icon"
                />
                <p>
                  Client <br /> Device
                </p>
              </div>
              <div>
                <Image
                  className="my-4"
                  src="./images/hardware-software/hs3.png"
                  width={50}
                  alt="icon"
                />
                <p>Networking</p>
              </div>
              <div>
                <Image
                  className="my-4"
                  src="./images/hardware-software/hs4.png"
                  width={50}
                  alt="icon"
                />
                <p>Servers</p>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12} className="p-4">
            <FirstBlock className="p-5">
              <h2>Here to serve all your IT needs</h2>
              <p className="my-4">
                Sourcing hardware from multiple vendors can be a hassle. We are
                here to simplify all your procurement needs by providing you
                with a single point of contact to manage all your IT equipment.
              </p>
              <p>
                From core devices including routers, switches, servers, and
                storage; to end-user devices such as tablets, smartphones, PCs,
                monitors and printers; to all the software you need to make it
                work together.
              </p>
            </FirstBlock>
          </Col>
        </Row>
        <Row style={{ padding: "80px 0px" }}>
          <Col md={4} sm={12} className="p-5">
            <Image
              style={{ borderRadius: "5px" }}
              width="100%"
              src="./images/hardware-software/bg2.png"
              alt="bg image"
              fluid
            />
          </Col>
          <Col
            md={8}
            sm={12}
            className="p-4 d-flex jutsify-content-center align-items-center"
          >
            <div className="p-4">
              <h2>Focus on your business and leave the rest to us</h2>
              <p className="py-4">
                Your dedicated account executive is here to remove all the
                complexity around managing multi-vendor procurement and
                logistics all the while providing you with outstanding customer
                service.
              </p>
              <Image src="./images/quotation.png" width={80} alt="icon" />
              <p className="py-2">
                I have been working with Richlink for a year now and find their
                services to be excellent on all levels. Richlink’s support staff
                are extremely knowledgeable and quick to respond with a positive
                professional attitude. Brooke, our account manager, is always on
                top of anything I bring to the table and amazes me and my team
                with the level of support, always making us feel we are number
                one on her list. I would not hesitate recommending Richlink to
                any company looking for reliable, knowledgeable service and
                support.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <div
        style={{
          padding: "80px 0px",
          backgroundImage: "url(./images/hardware-software/ai-bg.jpeg)",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <h2 className="text-center mb-5">
            A Smart approch to technology solutions
          </h2>
          <Row>
            <Col md={4} sm={12} className="p-4">
              <ActivityItem className="myshadow">
                <h5 className="text-main">Competitve Pricing</h5>
                <p className="my-4">
                  We manage relationships with more than 30 vendors and we have
                  the scale and buying power to qualify for exceptional
                  discounts and rebates which we pass along to our customers.
                </p>
              </ActivityItem>
            </Col>
            <Col md={4} sm={12} className="p-4">
              <ActivityItem className="myshadow">
                <h5 className="text-main">Logistics Excellence</h5>
                <p className="my-4">
                  As a North American solutions provider with operations and
                  distribution centers situated within both the US and Canada,
                  we are fully equipped to fulfill all supply chain requirements
                  big and small.
                </p>
              </ActivityItem>
            </Col>
            <Col md={4} sm={12} className="p-4">
              <ActivityItem className="myshadow">
                <h5 className="text-main">Flexible Financing</h5>
                <p className="my-4">
                  We facilitate your procurement cycles by offering flexible
                  financing solutions. We coordinate for you leasing solutions
                  with our vendor partners to help you manage your budget
                  efficiently and lower your total cost of ownership.
                </p>
              </ActivityItem>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

const ActivityItem = styled.div`
  background-color: #151515;
  padding: 40px;
  border-radius: 5px;
  height: 300px;
  @media only screen and (max-width: 768px) {
    height: 340px;
  }
`;

const FirstBlock = styled.div`
  background-image: url("./images/hardware-software/bg1.png");
  border-radius: 5px;
`;
