import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import styled from "styled-components";

export default function Layout(props: any) {
  return (
    <div>
      <Header />
      <ContentWrapper>{props.children}</ContentWrapper>
      <Footer />
    </div>
  );
}

const ContentWrapper = styled.div`
  padding-top: 150px;

  background-image: url("./images/bg.jpeg");
  background-size: cover;

  min-height: 80vh;
  width: 100vw;
  @media (max-width: 768px) {
    padding-top: 140px;
    margin: 0px;
  }
`;
