import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <Wrapper className="py-3">
      <Container>
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start p-3">
          <div className="my-3 d-none">
            <img
              className="d-block mb-4 m-auto"
              src="./images/logo-dark.png"
              height={80}
              alt="logo"
            />
            <SocialIcon>
              <a
                href="mailto:info@richlink.ca
              "
              >
                <FontAwesomeIcon
                  style={{ fontSize: "25px" }}
                  icon={faEnvelope}
                  className="text-light mx-3"
                />
              </a>
            </SocialIcon>
            <SocialIcon>
              <a href="https://www.linkedin.com/company">
                <FontAwesomeIcon
                  style={{
                    fontSize: "25px",
                  }}
                  icon={faLinkedin}
                  className="text-light mx-3"
                />
              </a>
            </SocialIcon>
            <SocialIcon>
              <a href="https://twitter.com/RichlinkTech">
                <FontAwesomeIcon
                  style={{ fontSize: "25px" }}
                  icon={faTwitter}
                  className="text-light mx-3"
                />
              </a>
            </SocialIcon>
          </div>
          <div>
            <ItemTitle>Our Products</ItemTitle>
            <a href="#products">
              <Item>Products</Item>
            </a>
            {/* <Link to="/blockchain">
              <Item>Blockchain</Item>
            </Link>
            <Link to="/clouddata">
              <Item>Cloud {"&"} Data Center</Item>
            </Link> */}
          </div>
          <div>
            <ItemTitle>Our Company</ItemTitle>
            <a href="#about">
              <Item>About us</Item>
            </a>
            {/* <Link to="/events">
              <Item>Our Events</Item>
            </Link> */}
          </div>
          <div>
            <ItemTitle>Our Location</ItemTitle>
            <p style={{ fontWeight: 300 }}>
              908 - 1112 W Pender St, <br />
              Vancouver, BC. V6E 2S1. <br />
              Canada
            </p>
          </div>
        </div>
        <hr style={{ border: "1px solid #fff" }} />
        <p
          className="my-5 text-center"
          style={{ fontWeight: 300, fontSize: "14px" }}
        >
          © {new Date().getFullYear()} Richlink Technologies. All Rights
          Reserved.
        </p>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  /* background-image: url("./images/bg.jpeg"); */
  background-size: cover;
  background-color: #342049;
  /* box-shadow: 0 4px 8px 0 black, 0 6px 20px 0 black; */
`;

const ItemTitle = styled.h6`
  margin: 20px 0px !important;
`;

const Item = styled.p`
  margin: 5px 0px;
  font-weight: 300;
  font-size: 15px;
  :hover {
    color: #da96fc;
    cursor: pointer;
  }
`;

const SocialIcon = styled.div`
  display: inline-block;
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
