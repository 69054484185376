import React from "react";
import ReactDOM from "react-dom";
import Routing from "./routing/routing";
import "./styles/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";

AOS.init({ once: true });

ReactDOM.render(<Routing />, document.getElementById("root"));
