import React from "react";
import Layout from "../../components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

export default function CloudData() {
  return (
    <Layout>
      <Container>
        <h1 style={{ padding: "40px 0px" }}>Cloud {`&`} Data Center</h1>
        <Row>
          <Col md={6} sm={12} className="p-4">
            <h2 className="mt-5">Cutting-Edge Cloud Solutions</h2>
            <p className="my-4">
              From Amazon Web Services, Microsoft Azure or Alibaba Enterprise
              Cloud, our technical certifications span all platforms and assure
              you of the most up to date and suitable guidance for any of your
              projects.
            </p>
          </Col>
          <Col md={6} sm={12} className="p-4">
            <img width="100%" src="./images/cloud-data/bg1.png" alt="bg" />
          </Col>
        </Row>
        <InfoBlock>
          <h2 className="text-center my-4">Our Certified Partners</h2>
          <div className="d-flex flex-column flex-sm-row justify-content-around align-items-center">
            <div className="p-3">
              <img width={100} src="./images/cloud-data/aws.png" alt="aws" />
            </div>
            <p className="p-3 m-0">
              Cloud Services are qualified to architect Amazon, Microsoft cloud
              infrastructures. Our ability to host client environments in a TIER
              3 Data Center facility with connectivity in to public cloud
              provides a secure, enterprise grade platform that gives our
              clients piece of mind.
            </p>
          </div>
          <div className="d-flex flex-column flex-sm-row justify-content-around align-items-center">
            <div className="p-3">
              <img width={100} src="./images/cloud-data/aws.png" alt="aws" />
            </div>
            <p className="p-3 m-0">
              Richlink Public Cloud consultancy, managed and professional
              services offer industry standard configuration and support
              mechanisms. We work with clients to take stock of their public
              cloud infrastructures, focus on costs savings and ensure best
              practices are implemented.
            </p>
          </div>
        </InfoBlock>
        <div
          style={{
            width: "50%",
            margin: "auto",
            paddingTop: "80px",
            textAlign: "center",
          }}
        >
          <h3 className="mb-4">Cloud Monitoring</h3>
          <p className="py-3 text-light">
            Richlink provides cloud monitoring solutions for our clients cloud
            applications, servers and networks.
          </p>
          <p className="text-light">
            Our Monitoring team develops a monitoring strategy that works with
            private, hybrid and public cloud platforms (Azure, AWS).
          </p>
        </div>
        <div className="py-5">
          <div
            className="d-flex flex-wrap justify-content-center align-items-center"
            style={{ width: "80%", margin: "auto" }}
          >
            <div className="p-4">
              <div className="d-flex flex-row justify-content-center align-items-center">
                <img
                  width={60}
                  src="./images/cloud-data/checked.png"
                  alt="checked"
                />
                <p className="m-0 mx-4">
                  Virtual instance and <br /> Workloads Monitoring
                </p>
              </div>
            </div>
            <div className="p-4">
              <div className="d-flex flex-row justify-content-center align-items-center">
                <img
                  width={60}
                  src="./images/cloud-data/checked.png"
                  alt="checked"
                />
                <p className="m-0 mx-4">
                  Web Applications and
                  <br /> Sites Monitoring
                </p>
              </div>
            </div>
            <div className="p-4">
              <div className="d-flex flex-row justify-content-center align-items-center">
                <img
                  width={60}
                  src="./images/cloud-data/checked.png"
                  alt="checked"
                />
                <p className="m-0 mx-4">
                  Cloud Networks
                  <br /> Monitoring
                </p>
              </div>
            </div>
            <div className="p-4">
              <div className="d-flex flex-row justify-content-center align-items-center">
                <img
                  width={60}
                  src="./images/cloud-data/checked.png"
                  alt="checked"
                />
                <p className="m-0 mx-4">
                  Performance <br /> Monitoring
                </p>
              </div>
            </div>
            <div className="p-4">
              <div className="d-flex flex-row justify-content-center align-items-center">
                <img
                  width={60}
                  src="./images/cloud-data/checked.png"
                  alt="checked"
                />
                <p className="m-0 mx-4">
                  Monthly Cloud Performance <br /> and Security Reports
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="p-5">
          <img width="100%" src="./images/cloud-data/bg2.png" alt="bg" />
        </div>
      </Container>
    </Layout>
  );
}

const InfoBlock = styled.div`
  width: 60%;
  padding: 30px;
  background-color: #4d4df9;
  opacity: 0.8;
  margin-top: -140px;
  border-radius: 5px;
  @media (max-width: 1334px) {
    width: 100%;
    margin-top: 0px;
  }
`;
