import styled from "styled-components";
import "../styles/mobile-nav.scss";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import WebNav from "./WebNav";

export default function Header() {
  // const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Wrapper>
      <Container>
        <div className="d-flex flex-row justify-content-between align-items-center position-relative">
          <Link to="/">
            <div className="d-none d-lg-inline-block">
              <LogoImage src="./images/logo-dark.png" alt="logo-title" />
              <img
                height={22}
                className="mt-1 px-2"
                src="./images/richlink-text.png"
                alt="richlink"
              />
            </div>
            <div className="d-lg-none">
              <LogoMobileImage src="./images/logo-dark.png" alt="logo-title" />
            </div>
          </Link>
          <div className="d-none d-lg-block">
            <WebNav />
          </div>
          {/* <div className="d-block d-lg-none">
            <div id="mobileHeader mx-4">
              <div
                className={isMenuOpen ? "menu-btn open" : "menu-btn mx-3"}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <div className="menu-btn__burger"></div>
              </div>
            </div>
          </div> */}
        </div>
      </Container>
      {/* <div
        className="myshadow"
        style={{
          display: isMenuOpen ? "initial" : "none",
        }}
      >
        <div className="p-0 mobile-nav d-flex flex-column text-center animate__animated animate__fadeIn">
          <MobileNavItem>
            <Link to="/">Home</Link>
          </MobileNavItem>
          <MobileNavItem>
            <Link to="/portfolio">Portfolio</Link>
          </MobileNavItem>
          <MobileNavItem>
            <Link to="/about">About</Link>
          </MobileNavItem>
        </div>
      </div> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  z-index: 999;

  /* background-image: url("./images/bg.jpeg"); */
  background-color: #2a223a;
  background-size: 400%;

  /* background-color: #0c0c0c; */
  padding: 14px 0px;
  /* box-shadow: 0 4px 8px 0 black, 0 6px 20px 0 black; */
  /* box-shadow: 0 2px 2px 0 #da96fc, 0 3px 10px 0 #da96fc; */
  /* box-shadow: rgba(0, 0, 0) 0px 30px 90px; */
  /* box-shadow: 0 2px 2px 0 #da96fcef, 0 3px 10px 0 #da96fc; */
`;

const LogoImage = styled.img`
  width: 50px;
  margin-left: -20px;
`;

const LogoMobileImage = styled.img`
  width: 44px;
  margin: 10px;
`;

// const MobileNavItem = styled.div`
//   padding: 20px;
//   font-size: 18px;
//   a {
//     color: white;
//   }
// `;
