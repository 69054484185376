/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/style-prop-object */
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import { Container, Row, Col, Image } from "react-bootstrap";
import notify from "../utilities/notify";
import { ToastContainer } from "react-toastify";

export default function About() {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const validateEmail = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSendMessage = () => {
    if (name === "") {
      notify("warn", "Your name can not be empty!");
      return;
    }
    if (email === "") {
      notify("warn", "Your email address can not be empty!");
      return;
    }
    if (!validateEmail()) {
      notify("warn", "Please input correct email format!");
      return;
    }
    if (message === "") {
      notify("warn", "Your message can not be empty!");
      return;
    }
    notify("success", "Thank you for your subscription!");
  };

  return (
    <Layout>
      <ToastContainer />
      <Container>
        <Row className="d-flex justify-content-between align-items-center">
          <Col md={6} sm={12} className="p-4">
            <h1 className="my-4">About Us</h1>
            <h2 className="my-5">Richlink Technologies Inc</h2>
            <p className="my-5">
              Richlink technologies is a high-tech start up focusing on
              development of web3 products, blockchain and social media
              applications.
            </p>
          </Col>
          <Col md={6} sm={12} className="p-4">
            <Image src="./images/about/cover.png" width="100%" fluid />
          </Col>
        </Row>
      </Container>
      <Section7>
        <Container>
          <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center p-5 my-5">
            <img
              className="m-3"
              src="./images/quotation.png"
              width={200}
              alt="quotation"
            />
            <p className="my-0 mx-4" style={{ fontSize: "18px" }}>
              At Richlink, we are passionate about leveraging technology to
              empower businesses and individuals alike. With a dedicated team of
              experts, we strive to deliver high-quality software solutions
              tailored to meet the unique needs of our clients.
            </p>
          </div>
        </Container>
      </Section7>
      <Section8>
        <Container>
          <h1 className="px-5 my-3">Our Vision</h1>
          <p className="px-5 my-5">
            We bring the best technical solutions to our clinets.
          </p>
          <div className="d-flex flex-column flex-md-row justify-content-around align-items-center pb-4">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <img
                className="m-3"
                src="./images/about/vision-icon1.png"
                width={50}
                alt="quotation"
              />
              <p
                className="m-0"
                style={{
                  width: "140px",
                }}
              >
                Technical <br /> Solutions
              </p>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <img
                className="m-3"
                src="./images/about/vision-icon2.png"
                width={50}
                alt="quotation"
              />
              <p
                className="m-0"
                style={{
                  width: "140px",
                }}
              >
                Partners {"&"} <br /> Co-operations
              </p>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <img
                className="m-3"
                src="./images/about/vision-icon3.png"
                width={50}
                alt="quotation"
              />
              <p
                className="m-0"
                style={{
                  width: "140px",
                }}
              >
                Development {"&"} <br /> Deployment
              </p>
            </div>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <img
                className="m-3"
                src="./images/about/vision-icon4.png"
                width={50}
                alt="quotation"
              />
              <p
                className="m-0"
                style={{
                  width: "140px",
                }}
              >
                Modern <br /> Technologies
              </p>
            </div>
          </div>
        </Container>
      </Section8>
      <Section9>
        <Container>
          <Row>
            <Col md={6} sm={12} className="p-5">
              <h2>Get In Touch</h2>
              <div className="my-5">
                <div className="d-flex flex-row justify-content-start align-items-center my-4">
                  <Image src="./images/about/map-icon1.png" width={20} fluid />
                  <p className="m-0 mx-3">
                    #908 - 1112 W Pender st, Vancouver, BC, V6E 2S1
                  </p>
                  {/* <p className="m-0 mx-3">
                    #820 - 1112 W Pender st, Vancouver, BC, V6E 2S1
                  </p> */}
                </div>
                <div className="d-flex flex-row justify-content-start align-items-center my-4">
                  <Image src="./images/about/map-icon2.png" width={20} fluid />
                  <p className="m-0 mx-3">+1 (604) - 789 - 6812</p>
                </div>
                <div className="d-flex flex-row justify-content-start align-items-center my-4">
                  <Image src="./images/about/map-icon3.png" width={20} fluid />
                  <p className="m-0 mx-3">info@richlink.ca</p>
                </div>
              </div>
              <div>
                <Row>
                  <Col md={5} sm={12} className="my-3 my-md-0">
                    <Input
                      type="text"
                      placeholder="Name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Col>
                  <Col md={7} sm={12}>
                    <Input
                      type="text"
                      placeholder="Email Address"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} sm={12} className="py-4">
                    <Textarea
                      placeholder="Write your message here"
                      onChange={(e) => setMessage(e.target.value)}
                    ></Textarea>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} sm={12}>
                    <SendButton onClick={handleSendMessage}>Send</SendButton>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={6} sm={12} className="p-0">
              {/* <Image src="./images/about/map.png" width="100%" fluid /> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2602.410521497732!2d-123.12333418423714!3d49.287566078345485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54867183e0d6c3e9%3A0x60ecfcb89b8aa4ad!2s1112%20W%20Pender%20St%2C%20Vancouver%2C%20BC%20V6E%202S1!5e0!3m2!1sen!2sca!4v1638309492188!5m2!1sen!2sca"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                loading="lazy"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </Section9>
    </Layout>
  );
}

const Section7 = styled.div`
  background-color: #151515;
`;

const Section8 = styled.div`
  padding-top: 40px;
  padding-bottom: 80px;
`;

const Section9 = styled.div`
  background-color: blue;
`;

const Input = styled.input`
  padding: 15px;
  width: 100%;
  background-color: #fff;
  border: 0px;
  border-radius: 5px;
  outline: 0px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 160px;
  padding: 15px;
  background-color: #fff;
  border: 0px;
  border-radius: 5px;
  outline: 0px;
`;

const SendButton = styled.button`
  width: 100%;
  outline: 0px;
  background-color: #42d2fa;
  color: #fff;
  padding: 20px;
  border: 0px;
  border-radius: 5px;
  outline: 0px;
`;
