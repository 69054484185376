import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
//
import Solutions from "../pages/Solutions";
import HardwareSoftware from "../pages/SolutionsChildren/HardwareSoftware";
import BlockChain from "../pages/SolutionsChildren/BlockChain";
import CloudData from "../pages/SolutionsChildren/CloudData";
//
import Products from "../pages/Products";
import Partners from "../pages/Partners";
import Portfolio from "../pages/Portfolio";
import ScrollToTop from "./scrollToTop";

import AboutIM from "../pages/AboutIM";
import AboutIMZH from "../pages/AboutIMZH";

export default function routing() {
  const routingMap = [
    { path: "/about", component: <About /> },
    //
    { path: "/solutions", component: <Solutions /> },
    { path: "/hardware-software", component: <HardwareSoftware /> },
    { path: "/blockchain", component: <BlockChain /> },
    { path: "/clouddata", component: <CloudData /> },
    { path: "/about-im", component: <AboutIM /> },
    { path: "/about-im-zh", component: <AboutIMZH /> },
    //
    { path: "/products", component: <Products /> },
    { path: "/portfolio", component: <Portfolio /> },
    { path: "/partners", component: <Partners /> },
    { path: "/", component: <Home /> },
  ];

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          {routingMap.map((el) => (
            <Route path={el.path}>{el.component}</Route>
          ))}
        </Switch>
      </ScrollToTop>
    </Router>
  );
}
