import React from "react";
import Layout from "../../components/Layout";
import { Container, Row, Col, Image } from "react-bootstrap";
import styled from "styled-components";

export default function BlockChain() {
  return (
    <Layout>
      <Section1>
        <Container>
          <Row>
            <Col md={6} sm={12} className="p-5">
              <h1 style={{ paddingBottom: "60px" }}>
                BlockChain Development <br /> for Enterprises
              </h1>
              <h2 className="my-4">
                Our digital assets center is under the construction
              </h2>
              <p className="my-2">
                Richlink is building a 60MW digital assets mining center in
                brandon, MB, Canada. We are welcome all co-operations with you.
              </p>
              <p className="my-3">
                Our large mining center will provide hosting services for
                branded mining machines such as Ant mining machine hosting,
                Avalon mining machine hosting, whatsup mining machine hosting,
                etc. Please feel free to contact us for the details.
              </p>
            </Col>
            <Col
              md={6}
              sm={12}
              className="p-5 d-flex flex-column justify-content-center align-items-center"
            >
              <Image
                src="./images/blockchain/bitcoin-hand.png"
                width={400}
                fluid
              />
              <ActivityItem className="myshadow">
                <div className="d-flex flex-row justify-content-start align-items-center">
                  <img
                    src="./images/home/blockchain.png"
                    width={60}
                    alt="icon"
                  />
                  <h5 className="text-shadow mx-4">Blockchain</h5>
                </div>
                <p className="my-4">
                  We are establishing a 60MW digital assets mining center in
                  Canada. The data center is under the construction currently.
                </p>
              </ActivityItem>
            </Col>
          </Row>
        </Container>
      </Section1>
      <Section2>
        <Container className="p-5 text-center">
          <h2 className="m-0 pb-3">Richlink Mining Machine Hosting Process</h2>
          <h2 className="m-0 pb-5">
            Simple 4 Steps - Easy Mining Machine Hosting
          </h2>
          <p>
            Step 1: Choose a mining machine hosting plan after purchasing the
            mining machine
          </p>
          <p>Step 2: Wait for delivery to the mining machine hosting center</p>
          <p>
            Step 3: Managed and put on the shelves by our technicans after your
            machines arriving at our mining center
          </p>
          <p>Step 4: Start mining and earn your income immediately</p>
        </Container>
      </Section2>
      <Section3>
        <Container className="text-center">
          <h2 className="m-0 p-4">What Are the Use Cases for Blockchain?</h2>
          <p className="m-0 p-3">
            Blockchain technology has long evolved and is now changing the way
            traditional industries operate. <br /> There are many strategic uses
            cases for blockchain technology, including:
          </p>
          <Row className="p-5">
            <Col md={5} sm={12} className="p-5">
              <Image
                style={{ borderRadius: "5px" }}
                src="./images/blockchain/typing.png"
                width="100%"
                fluid
              />
            </Col>
            <Col md={7} sm={12}>
              <Row>
                <Col md={6} sm={12} className="p-5">
                  <IconBar>
                    <Image
                      src="./images/blockchain/icon1.png"
                      width={40}
                      fluid
                    />
                    <p className="m-0 mx-4">Financial Institutions</p>
                  </IconBar>
                  <IconBar>
                    <Image
                      src="./images/blockchain/icon2.png"
                      width={40}
                      fluid
                    />
                    <p className="m-0 mx-4">Identity Verification</p>
                  </IconBar>
                  <IconBar>
                    <Image
                      src="./images/blockchain/icon3.png"
                      width={40}
                      fluid
                    />
                    <p className="m-0 mx-4">Payments</p>
                  </IconBar>
                  <IconBar>
                    <Image
                      src="./images/blockchain/icon4.png"
                      width={40}
                      fluid
                    />
                    <p className="m-0 mx-4">Healthcare</p>
                  </IconBar>
                </Col>
                <Col md={6} sm={12} className="p-5">
                  <IconBar>
                    <Image
                      src="./images/blockchain/icon5.png"
                      width={40}
                      fluid
                    />
                    <p className="m-0 mx-4">Manufacturing</p>
                  </IconBar>
                  <IconBar>
                    <Image
                      src="./images/blockchain/icon6.png"
                      width={40}
                      fluid
                    />
                    <p className="m-0 mx-4">Insurance</p>
                  </IconBar>
                  <IconBar>
                    <Image
                      src="./images/blockchain/icon7.png"
                      width={40}
                      fluid
                    />
                    <p className="m-0 mx-4">Logistics</p>
                  </IconBar>
                  <IconBar>
                    <Image
                      src="./images/blockchain/icon8.png"
                      width={40}
                      fluid
                    />
                    <p className="m-0 mx-4">Immigration</p>
                  </IconBar>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Section3>
      <Section4>
        <Container>
          <h2 className="p-4 pt-0">Explore Our Automation Capabilities</h2>
          <p className="p-4">
            We work with leading Canadian companies transform form they way they
            do business, stream operations and find new efficiencies with
            Automation technologies and platforms.
          </p>
          <Row>
            <Col md={4} sm={12} className="p-4">
              <CapabilitySection>
                <h5
                  className="p-5"
                  style={{
                    backgroundImage: 'url("./images/blockchain/cp1.png")',
                  }}
                >
                  Custom Blockchain <br /> Development
                </h5>
                <p className="p-5">
                  We can design, develop, and implement any blockchain solution
                  that your organization needs to maintain its competitive
                  advantages.
                </p>
              </CapabilitySection>
            </Col>
            <Col md={4} sm={12} className="p-4">
              <CapabilitySection>
                <h5
                  className="p-5"
                  style={{
                    backgroundImage: 'url("./images/blockchain/cp2.png")',
                  }}
                >
                  Proof Of Concept (POC) <br /> Development
                </h5>
                <p className="p-5">
                  Looking to test a new idea on the market? We can develop a PoC
                  that will allow you to validate whether it’s viable on the
                  market.
                </p>
              </CapabilitySection>
            </Col>
            <Col md={4} sm={12} className="p-4">
              <CapabilitySection>
                <h5
                  className="p-5"
                  style={{
                    backgroundImage: 'url("./images/blockchain/cp3.png")',
                  }}
                >
                  AI / RPA <br /> Integration
                </h5>
                <p className="p-5">
                  Our team of automation experts can help you drive more value
                  out of your blockchain solutions through AI and RPA
                  integrations.
                </p>
              </CapabilitySection>
            </Col>
          </Row>
          <Row>
            <Col md={4} sm={12} className="p-4">
              <CapabilitySection>
                <h5
                  className="p-5"
                  style={{
                    backgroundImage: 'url("./images/blockchain/cp4.png")',
                  }}
                >
                  Blockchain <br /> Consulting
                </h5>
                <p className="p-5">
                  Blockchain technology is complex. Our consultants can walk you
                  through the various use cases and applications in your
                  industry.
                </p>
              </CapabilitySection>
            </Col>
            <Col md={4} sm={12} className="p-4">
              <CapabilitySection>
                <h5
                  className="p-5"
                  style={{
                    backgroundImage: 'url("./images/blockchain/cp5.png")',
                  }}
                >
                  Smart <br /> Contracts
                </h5>
                <p className="p-5">
                  Do you do a large volume of high-risk transactions? Smart
                  contracts improve your ability to transact, track, and audit
                  transactions.
                </p>
              </CapabilitySection>
            </Col>
            <Col md={4} sm={12} className="p-4">
              <CapabilitySection>
                <h5
                  className="p-5"
                  style={{
                    backgroundImage: 'url("./images/blockchain/cp6.png")',
                  }}
                >
                  Blockchain Support <br /> Services
                </h5>
                <p className="p-5">
                  Any blockchain solution you create will require updates and
                  maintenance. Our team can reduce your IT costs by taking on
                  this task.
                </p>
              </CapabilitySection>
            </Col>
          </Row>
        </Container>
      </Section4>
      <Section5>
        <div className="text-center">
          <h2 className="m-4">
            How Does Our Blockchain Development Process Work?
          </h2>
          <p className="m-4">
            Our team works to integrate your new blockchain solution into your
            existing workflows.
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-md-row flex-column">
            <div className="text-center m-5">
              <Image
                src="./images/blockchain/process-icon1.png"
                width={80}
                fluid
              />
              <p className="my-4">Determine Your Goals</p>
            </div>
            <div className="text-center m-5">
              <Image
                src="./images/blockchain/process-icon2.png"
                width={80}
                fluid
              />
              <p className="my-4">Initial Development</p>
            </div>
            <div className="text-center m-5">
              <Image
                src="./images/blockchain/process-icon3.png"
                width={80}
                fluid
              />
              <p className="my-4">Delivery</p>
            </div>
            <div className="text-center m-5">
              <Image
                src="./images/blockchain/process-icon4.png"
                width={80}
                fluid
              />
              <p className="my-4">Implementation</p>
            </div>
            <div className="text-center m-5">
              <Image
                src="./images/blockchain/process-icon5.png"
                width={80}
                fluid
              />
              <p className="my-4">Ongoing Support</p>
            </div>
          </div>
        </div>
      </Section5>
    </Layout>
  );
}

const Section1 = styled.div`
  padding-bottom: 80px;
`;

const ActivityItem = styled.div`
  background-color: #151515;
  padding: 30px 40px;
  border-radius: 5px;
  position: relative;
`;

const Section2 = styled.div`
  padding: 20px;
  background-image: url("./images/blockchain/banner.png");
`;

const Section3 = styled.div`
  padding-top: 80px;
`;

const IconBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
`;

const CapabilitySection = styled.div`
  background-color: #151515;
  border-radius: 5px;
  h5 {
    background-size: cover;
    padding: 30px 40px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    height: 130px;
  }
  p {
    padding: 40px;
    height: 200px;
  }
`;

const Section4 = styled.div`
  padding: 100px 0px;
`;

const Section5 = styled.div`
  padding-top: 60px;
  background-color: #151515;
`;
