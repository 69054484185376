import { toast } from "react-toastify";

const notifyConfig: Object = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const handleToasting = (type: string, str: string) => {
  switch (type) {
    case "warn":
      toast.warn(str, notifyConfig);
      break;
    case "error":
      toast.error(str, notifyConfig);
      break;
    case "success":
      toast.success(str, notifyConfig);
      break;
    case "info":
      toast.info(str, notifyConfig);
      break;
    default:
      toast.error("Unexpected Error!", notifyConfig);
      break;
  }
};

export default handleToasting;
