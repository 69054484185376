import React from "react";
import Layout from "../components/Layout";
import { Container } from "react-bootstrap";
import styled from "styled-components";

export default function Partners() {
  const imageNames: Array<string> = [
    "antminer",
    "avalon",
    "whatsminer",
    "binance",
    "coinMarketCap",
    "trustWallet",
    "shakePay",
    "aws",
    "netlify",
    "github",
    "paypal",
    "visa",
    "mastercard",
    "wechatpay",
    "alipay",
  ];

  return (
    <Layout>
      <Container className="text-center">
        <Wrapper>
          {imageNames.map((el: string) => (
            <Img
              className="animate__animated animate__fadeInUp"
              src={"./images/partners/logos/" + el + ".png"}
            />
          ))}
        </Wrapper>
      </Container>
    </Layout>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  h1 {
    display: inline;
    padding: 20px;
    :hover {
      color: #da96fc;
      cursor: pointer;
    }
  }
`;

const Img = styled.img`
  margin: 40px;
  width: 120px;
  border-radius: 5px;
`;
