import React from "react";
import styled from "styled-components";

export default function AboutUs() {
  return (
    <StyledWrapper>
      <div className="container">
        <h1>《Vennchat 即时通讯 隐私政策》</h1>
        <p>最后更新日期：2023.5月</p>

        <p>
          欢迎来到我们的应用（"Vennchat"）。我们致力于保护我们用户的隐私和他们的个人信息。本隐私政策（"政策"）解释了我们如何收集、使用和披露关于我们的用户及其在App上的活动的信息。
        </p>

        <p>
          我们收集的信息： <br />
          账户信息:
          当您创建Vennchat账户时，您会注册一个电话号码。电话号码用于向您和其他Vennchat用户提供我们的服务。您可以选择在您的账户中添加其他信息，如个人资料名称和个人资料图片。
          我们可能会在我们的服务器上收集这些个人信息，如个人资料名称和个人资料图片，信息是端到端加密的。
        </p>

        <p>
          位置数据:
          我们可能通过您设备的GPS或其他位置追踪技术收集有关您的位置的信息。{" "}
          <br />
          浏览历史: 我们可能收集关于您在App上查看的页面、帖子和其他内容的信息。
        </p>

        <p>
          信息的使用：
          使您的体验个性化:我们使用您的信息在App上为您提供个性化的体验，向他人显示使用者的昵称、头像及个人资料，例如推荐您可能感兴趣的内容或功能。您可以在Vennchat的应用程序设置中管理您的个人信息。例如，您可以更新您的个人资料信息。
          有针对性的广告:我们可能使用您的信息在App上以及其他网站和App上向您提供有针对性的广告。
          改进App:我们可能使用您的信息来改进App并开发新功能。
        </p>

        <p>
          我们所提供产品或服务的功能分别收集的个人信息类型，以及收集、使用个人信息的规则（例如收集和使用个人信息的目的、方式）如下：
        </p>
        <p>
          1、当您注册Vennchat服务时，我们会收集您的手机号码用于创建账户和使用社交功能。您可以选择在您的账户中添加其他信息，如个人资料名称和个人资料图片，您添加的名称与图片，会存储在我们的服务器中，因为存储是实现这一功能所必需的，我们会以加密的方式存储，您也可以随时去掉这些信息。我们仅将在本应用程序内使用您的相关信息。 在注册与使用服务过程中，如果您提供以下信息补全个人资料：年龄、所在城市、职业、学校，将有助于我们给您提供更好的服务和体验，我们可以为您提供与以上信息相关的个性化服务。但如果您不提供这些信息，仅将会使您无法使用或参与与此相关的个性化服务，但不会影响使用Vennchat产品或服务的基本功能。您提供的上述信息将在您使用本服务期间持续授权我们使用。在您主动注销账号时，我们将根据适用法律法规的删除您的个人信息。
        </p>

        <p>
          2、当您使用群组、发现、我的时光及其他信息发布功能公开发布信息，包括发布图文/视频/发表评价时，您需要相应的开启相册、相机、麦克风权限、位置权限来拍摄照片或视频或语音交互或显示所在位置。请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息，如您在发布动态时选择上传包含个人信息的图片，请您更加谨慎地考虑。您上传的动态照片、短视频、评论、点赞等信息会存储在我们的服务器中，因为存储是实现这一功能所必需的。我们会以加密的方式存储，您也可以随时删除这些信息。除非经您自主选择或遵从相关法律法规要求，我们不会对外提供上述动态信息，或者将其用于该功能以外的其他用途。
        </p>

        <p>
          信息的分享： <br />
          第三方服务提供者:我们可能与协助我们运营App的第三方服务提供商分享您的信息，如托管提供商和分析公司。
          遵守法律:如果法律要求或我们认为有必要保护我们的用户或公众的安全，我们可能向政府或执法机构披露您的信息。
        </p>

        <p>
          数据保留： <br />
          我们在提供App服务和遵守法律及监管要求的必要时间内保留您的信息。
        </p>

        <p>
          您的权利： <br />
          访问和控制您的信息:您有权访问和控制您在App上的个人信息，包括更新、更正或删除您的账户的权利。选择退出定向广告：您可以在任何时候选择退出接收App上的目标广告。
        </p>

        <p>
          本政策的变更： <br />
          我们可能会不时地更新本政策。我们将通过在App上发布新政策并更新本页面顶部的
          "生效日期 "来通知您任何变更。
        </p>

        <p>
          联系我们： <br />
          如果您对本政策或App的隐私做法有任何疑问，请通过info@richlink.ca
          与我们联系。
        </p>

        <h1>《Vennchat 即时通讯 服务条款》</h1>
        <p>最后更新日期：2023.1月</p>

        <p>
          1) 简介：本服务条款是用户与Richlink Technologies
          Inc.之间关于使用社交媒体应用程序（“Richlink
          IM”）的法律协议。通过访问、安装或使用 Richlink IM
          应用程序，您同意受本服务条款的约束。如果您不同意本服务条款，请勿安装或使用该应用程序。
        </p>

        <p>
          2) 许可授予：Richlink Technologies Inc.
          授予您不可转让、非独占的许可，允许您在您拥有或控制的单个移动设备上下载、安装和使用
          Richlink IM App，仅供您个人使用。
        </p>

        <p>
          3)
          使用限制：您不得复制、分发、出售或以其他方式转让应用程序或其任何部分。您不得修改、逆向工程、反编译或创建基于应用程序或其任何部分的衍生作品。您不得将
          Richlink IM App 用于任何非法或未经授权的目的。
        </p>

        <p>
          4)知识产权：Richlink IM
          App及与之相关的所有知识产权，包括但不限于商标、专利、著作权等，均归Richlink
          Technologies Inc.所有，您不因此获得该App的任何所有权使用它。
        </p>

        <p>
          5)用户内容：您对您通过 Richlink IM App
          发布、上传或以其他方式提供的任何内容、信息或数据（“用户内容”）承担全部责任。您声明并保证您拥有或拥有使用和授权
          Richlink Technologies Inc. 使用您通过 Richlink IM App
          发布、上传或以其他方式提供的所有用户内容的必要权利。
        </p>

        <p>
          6) 免责声明：Richlink IM
          App“按原样”提供，不提供任何明示或暗示的保证，包括但不限于对适销性和特定用途适用性的暗示保证。
          Richlink Technologies Inc. 不保证 Richlink IM App
          将满足您的要求或其运行不会中断或没有错误。
        </p>

        <p>
          7)责任限制：Richlink Technologies Inc.不对因使用Richlink IM
          App而产生的任何损害承担责任，包括但不限于利润损失或数据损失。
        </p>

        <p>
          8) 终止：Richlink Technologies Inc.
          可随时出于任何原因终止本服务条款，无论是否通知您。终止后，您必须立即停止使用该应用程序并将其从您的移动设备中删除。
        </p>

        <p>
          9) 适用法律：本服务条款应受 Richlink Technologies Inc.
          所在地的法律管辖并据其解释。
        </p>

        <p>
          10) 完整协议：本服务条款构成您与 Richlink Technologies Inc.
          之间关于使用 Richlink IM App
          的完整协议，并取代所有先前的口头或书面协议、谅解和协商。
        </p>

        <p>
          11)接受：访问、安装或使用 Richlink IM
          App，即表示您接受本服务条款和条件。
        </p>

        <p>如果您对本服务条款有任何疑问，请通过info@richlink.ca 与我们联系。</p>
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  background-color: black;
  padding: 30px;
  min-height: 100vh;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  h1 {
    font-size: 26px;
    padding: 30px 0px;
  }
  a {
    color: #d995fa;
  }
  h3 {
    font-size: 16px;
    font-weight: 400;
    padding: 30px 0px;
  }
`;
