/* eslint-disable react/jsx-no-undef */
import { useState } from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer } from "react-toastify";

import notify from "../utilities/notify";

export default function Home() {
  const [email, setEmail] = useState("");

  const [name, setName] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const validateEmail = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubscribe = () => {
    if (email === "") {
      notify("warn", "Your email address can not be empty!");
    } else if (!validateEmail()) {
      notify("error", "Please input correct email format!");
    } else {
      notify("success", "Thank you for your subscription!");
      setEmail("");
    }
  };

  const handleSendMessage = () => {
    if (name === "") {
      notify("warn", "Your name can not be empty!");
      return;
    }
    if (email === "") {
      notify("warn", "Your email address can not be empty!");
      return;
    }
    if (!validateEmail()) {
      notify("warn", "Please input correct email format!");
      return;
    }
    if (message === "") {
      notify("warn", "Your message can not be empty!");
      return;
    }
    notify("success", "Thank you for your subscription!");
  };

  return (
    <Layout>
      <ToastContainer />

      <div id="home">
        <Section1>
          <Container>
            <Row className="d-flex flex-row justify-content-center align-items-start">
              <Col md={7} sm={12} className="p-4">
                {/* <img
                className="my-3 animate__animated animate__fadeInLeft"
                src="./images/home/discover.png"
                height={20}
                alt="icon"
              /> */}
                <Intro className="my-3 animate__animated animate__fadeInDown">
                  Richlink <br />
                  Empowering Innovation
                </Intro>
                <p className="my-4 animate__animated animate__fadeInDown">
                  Welcome to Richlink Technologies, where innovation meets
                  expertise in software development. We specialize in crafting
                  cutting-edge solutions for blockchain, Web3, and social media
                  applications, spanning both websites and mobile apps.
                </p>
                <p className="text-shadow my-5 animate__animated animate__fadeInLeft">
                  Transforming Ideas into Reality{" "}
                  <span className="mx-3">————</span>
                </p>
              </Col>
              <Col md={5} sm={12} className="p-5">
                <div className="my-4 animate__animated animate__fadeInRight mb-5">
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    src="https://static.vecteezy.com/system/resources/previews/021/627/856/non_2x/holo-hot-coin-stacks-cryptocurrency-3d-render-illustration-free-png.png"
                    alt="gif"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                md={3}
                sm={12}
                className="text-center animate__animated animate__fadeInLeft"
              >
                <img
                  className="my-2"
                  src="./images/home/it-service1.png"
                  width={60}
                  alt="icon"
                />
                <p className="my-2 text-shadow">Innovation</p>
                <p style={{ fontSize: "14px" }}>
                  We embrace creativity and push the boundaries of what's
                  possible in software development.
                </p>
              </Col>
              <Col
                md={3}
                sm={12}
                className="text-center animate__animated animate__fadeInDown"
              >
                <img
                  className="my-2"
                  src="./images/home/it-service4.png"
                  width={60}
                  alt="icon"
                />
                <p className="my-2 text-shadow">Expertise</p>
                <p style={{ fontSize: "14px" }}>
                  Our team consists of skilled professionals who are passionate
                  about their craft and committed to excellence.
                </p>
              </Col>
              <Col
                md={3}
                sm={12}
                className="text-center animate__animated animate__fadeInUp"
              >
                <img
                  className="my-2"
                  src="./images/home/it-service3.png"
                  width={60}
                  alt="icon"
                />
                <p className="my-2 text-shadow">Collaboration</p>
                <p style={{ fontSize: "14px" }}>
                  We believe in the power of teamwork and collaboration, both
                  internally and with our clients.
                </p>
              </Col>
              <Col
                md={3}
                sm={12}
                className="text-center animate__animated animate__fadeInRight"
              >
                <img
                  className="my-2"
                  src="./images/home/it-service2.png"
                  width={60}
                  alt="icon"
                />
                <p className="my-2 text-shadow">Customer Focus</p>
                <p style={{ fontSize: "14px" }}>
                  Our clients are at the heart of everything we do, and we
                  strive to exceed their expectations at every turn.
                </p>
              </Col>
            </Row>
          </Container>
        </Section1>
        <Section2>
          <div>
            <h1 className="text-center my-5">Our Activity</h1>
            <Container>
              <Row>
                <Col md={4} sm={12} className="p-4">
                  <ActivityItem className="myshadow" data-aos="fade-down">
                    <div className="d-flex flex-row justify-content-start align-items-center position-relative">
                      <img
                        src="./images/home/hardware-software.png"
                        width={60}
                        alt="icon"
                      />
                      <h5 className="text-shadow mx-4">Web3 Development</h5>
                    </div>
                    <p className="my-5">
                      Harness the potential of Web3 technologies such as IPFS
                      and decentralized identities with our expertise in
                      building next-generation web applications.
                    </p>
                    {/* <Link to="/hardware-software">
                    <Button2 text="Read More" />
                  </Link> */}
                  </ActivityItem>
                </Col>
                <Col md={4} sm={12} className="p-4">
                  <ActivityItem
                    className="myshadow"
                    data-aos="fade-down"
                    data-aos-delay="100"
                  >
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      <img
                        src="./images/home/blockchain.png"
                        width={60}
                        alt="icon"
                      />
                      <h5 className="text-shadow mx-4">
                        Blockchain Development
                      </h5>
                    </div>
                    <p className="my-5">
                      From smart contracts to decentralized applications, we
                      offer end-to-end blockchain solutions tailored to your
                      specific needs.
                    </p>
                    {/* <Link to="/blockchain">
                    <Button2 text="Read More" />
                  </Link> */}
                  </ActivityItem>
                </Col>
                <Col md={4} sm={12} className="p-4">
                  <ActivityItem
                    className="myshadow"
                    data-aos="fade-down"
                    data-aos-delay="200"
                  >
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      <img
                        src="./images/home/cloud.png"
                        width={60}
                        alt="icon"
                      />
                      <h5 className="text-shadow mx-4">
                        Social Media Applications
                      </h5>
                    </div>
                    <p className="my-5">
                      Elevate your online presence with our custom social media
                      platforms, designed to engage users and drive growth.
                    </p>
                    {/* <Link to="/clouddata">
                    <Button2 text="Read More" />
                  </Link> */}
                  </ActivityItem>
                </Col>
              </Row>
            </Container>
          </div>
          <Container data-aos="fade-in">
            <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center p-5 my-5">
              <img
                className="m-3"
                src="./images/quotation.png"
                width={200}
                alt="quotation"
              />
              <p className="my-0 mx-4" style={{ fontSize: "22px" }}>
                Our mission at Richlink is to drive digital transformation
                through innovative software development. By harnessing the power
                of blockchain, Web3, and social media technologies, we aim to
                revolutionize the way businesses engage with their customers and
                users.
              </p>
            </div>
          </Container>
        </Section2>
        <Section3>
          <Container className="text-center" data-aos="fade-in">
            <h2 className="my-4 text-white">Let's Keep in Touch</h2>
            <p
              style={{
                width: "50%",
                color: "#fff",
                margin: "auto",
                padding: "10px 0px",
                fontWeight: 200,
              }}
            >
              Richlink technologies is looking forward to the co-operations with
              you.
            </p>
            <div className="my-4 d-flex flex-column flex-sm-row justify-content-center align-items-center">
              <Input
                placeholder="Type Your Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <SubscribeButton onClick={handleSubscribe}>
                Submit
              </SubscribeButton>
            </div>
          </Container>
        </Section3>

        <Section4 className="container" id="products">
          {/* vennchat  */}
          <Row style={{ margin: "100px 0px" }}>
            <Col md={5} sm={12} className="p-4" data-aos="fade-up">
              <div className="small-title">Simple Private Chat</div>
              <div className="title">VennChat</div>
              <p>
                VennChat is a dynamic social media platform that connects users,
                leading to meaningful interactions andrelationships. By
                collaborating with trusted application publishers, VennChat
                creates abundantopportunities for personal growth, leadership
                development, and exploration within a fresh and excitingsocial
                media landscape.
              </p>
              <a href="https://apps.apple.com/ca/app/vennchat/id6443394582">
                <button>View More</button>
              </a>
            </Col>
            <Col md={7} sm={12} className="p-4" data-aos="fade-down">
              <img
                className="m-3"
                width={200}
                src="./images/vennchat1.png"
                alt=""
              />
              <img
                className="m-3"
                width={200}
                src="./images/vennchat2.png"
                alt=""
              />
              <img
                className="m-3"
                width={200}
                src="./images/vennchat3.png"
                alt=""
              />
            </Col>
          </Row>
          {/* vhot  */}
          <Row className="my-10" style={{ margin: "10px 0px" }}>
            <Col md={7} sm={12} className="p-4" data-aos="fade-left">
              <img
                className="m-3"
                width={200}
                src="./images/vhot1.png"
                alt=""
              />
              <img
                className="m-3"
                width={200}
                src="./images/vhot2.png"
                alt=""
              />
              <img
                className="m-3"
                width={200}
                src="./images/vhot3.png"
                alt=""
              />
            </Col>
            <Col md={5} sm={12} className="p-4" data-aos="fade-right">
              <div className="small-title">Record life with videos</div>
              <div className="title">Vhot</div>
              <p>
                Vhot is a video community product of Yexc Technologies inc. We
                are dedicated to providing a platformwhere all creators can be
                creative to record videos and exchange ideas with other
                creators. it provides userswith video viewing, recording,
                creating posts and sharing.
              </p>

              <a href="https://apps.apple.com/au/app/vhot/id1663976918">
                <button>View More</button>
              </a>
            </Col>
          </Row>
          {/* <Row style={{ margin: "120px 0px" }}>
            <Col md={6} sm={12} className="p-4" data-aos="fade-down">
              <div className="small-title">Web3 idle game</div>
              <div className="title">Crypto Hero</div>
              <p>
                Enbark on an entertaining journey with our web3 idle game -
                where fun meets simplicty. Engage in epic battles to earn USDT
                anytime, anywhere. Become a Crypto Hero today!
              </p>
              <a href="https://www.cryptohero.game/">
                <button>View More</button>
              </a>
            </Col>
            <Col
              md={6}
              sm={12}
              className="p-4 position-relative"
              data-aos="fade-up"
            >
              <img
                style={{
                  top: -20,
                  left: 0,
                }}
                className="position-absolute"
                width={260}
                src="./images/ch1.png"
                alt=""
              />
              <img
                style={{
                  top: 40,
                  left: 200,
                }}
                className="position-absolute"
                width={240}
                src="./images/ch2.png"
                alt=""
              />
              <img
                style={{
                  top: 40,
                  left: 380,
                }}
                className="position-absolute"
                width={240}
                src="./images/ch3.png"
                alt=""
              />
              <img
                style={{
                  top: 220,
                  left: 80,
                }}
                className="position-absolute"
                width={180}
                src="./images/ch4.png"
                alt=""
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "200px", marginBottom: "100px" }}>
            <Col
              md={6}
              sm={12}
              className="p-4 position-relative"
              data-aos="fade-right"
            >
              <img
                style={{
                  left: "20%",
                }}
                className="position-absolute"
                width={320}
                src="./images/ez1.png"
                alt=""
              />
              <img
                style={{
                  left: "20%",
                }}
                className="position-absolute"
                width={320}
                src="./images/ez2.png"
                alt=""
              />
            </Col>
            <Col md={6} sm={12} className="p-4" data-aos="fade-left">
              <div className="small-title">Cryptocurrency Trading Platform</div>
              <div className="title">EZ Coin</div>
              <p>
                A streamlined, secure, and professional trading platform to let
                users buy & sell crypto currencies easily.
              </p>
              <a href="https://www.ezcoin.io/">
                <button>View More</button>
              </a>
            </Col>
          </Row> */}
        </Section4>

        {/* talk  */}
        {/* <Section5>
          <Container>
            <h2 className="text-center">What our clients have to say</h2>
            <Row className="my-2">
              <Col
                md={6}
                sm={12}
                style={{ padding: "5%" }}
                data-aos="fade-right"
              >
                <div className="text-center" style={{ marginBottom: "-10%" }}>
                  <img
                    width="150px"
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDCAqJbUM-KOk8Z_pEHsZP67a6Q_XpJSKuzA&usqp=CAU"
                    style={{ borderRadius: "50%" }}
                    alt="avatar"
                  />
                </div>
                <CommentWrapper className="myshadow">
                  <p>
                    We chose RichLink to develop our financial software, and it
                    was an incredibly wise decision. Their team demonstrated
                    outstanding expertise and technical prowess. Throughout the
                    development process, they communicated effectively and
                    promptly addressed any issues. They delivered our project to
                    the highest standards and commitment, surpassing our
                    expectations. We're highly satisfied with the exceptional
                    work by the RichLink team, and we look forward to future
                    collaborations.
                  </p>
                  <h4 className="mt-4">Friderick</h4>
                  <h6 className="mt-2 text-light">Project Manager</h6>
                  <h6 className="mt-2 text-light">Ark Fintech Inc.</h6>
                </CommentWrapper>
              </Col>
              <Col
                md={6}
                sm={12}
                style={{ padding: "5%" }}
                data-aos="fade-left"
              >
                <div className="text-center" style={{ marginBottom: "-10%" }}>
                  <img
                    width="150px"
                    src="/images/39.png"
                    style={{ borderRadius: "50%" }}
                    alt="avatar"
                  />
                </div>
                <CommentWrapper className="myshadow">
                  <p>
                    We chose RichLink to develop our gaming software, and it was
                    an incredibly satisfying experience. Their team showcased
                    exceptional expertise and creativity throughout the
                    development process. They were responsive to our needs,
                    communicated effectively, and delivered a product that
                    exceeded our expectations. We're thoroughly pleased with the
                    outcome, and we highly recommend RichLink for their
                    outstanding work in game development.
                  </p>
                  <h4 className="mt-4">Fiota</h4>
                  <h6 className="mt-2 text-light">Co-Founder & CEO</h6>
                  <h6 className="mt-2 text-light">Dex Metropolis</h6>
                </CommentWrapper>
              </Col>
            </Row>
          </Container>
        </Section5> */}

        <Container id="about">
          <Row className="d-flex justify-content-between align-items-center">
            <Col md={6} sm={12} className="p-4">
              <h1 className="my-4">About Us</h1>
              <h2 className="my-5">Richlink Technologies Inc</h2>
              <p className="my-5">
                Richlink technologies is a high-tech start up focusing on
                development of web3 products, blockchain and social media
                applications.
              </p>
            </Col>
            <Col md={6} sm={12} className="p-4">
              <img src="./images/about/cover.png" width="100%" alt="" />
            </Col>
          </Row>
        </Container>
        <Section7>
          <Container>
            <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center p-5 my-5">
              <img
                className="m-3"
                src="./images/quotation.png"
                width={200}
                alt="quotation"
              />
              <p className="my-0 mx-4" style={{ fontSize: "18px" }}>
                At Richlink, we are passionate about leveraging technology to
                empower businesses and individuals alike. With a dedicated team
                of experts, we strive to deliver high-quality software solutions
                tailored to meet the unique needs of our clients.
              </p>
            </div>
          </Container>
        </Section7>
        <Section8>
          <Container>
            <h1 className="px-5 my-3">Our Vision</h1>
            <p className="px-5 my-5">
              We bring the best technical solutions to our clinets.
            </p>
            <div className="d-flex flex-column flex-md-row justify-content-around align-items-center pb-4">
              <div className="d-flex flex-row justify-content-center align-items-center">
                <img
                  className="m-3"
                  src="./images/about/vision-icon1.png"
                  width={50}
                  alt="quotation"
                />
                <p
                  className="m-0"
                  style={{
                    width: "140px",
                  }}
                >
                  Technical <br /> Solutions
                </p>
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <img
                  className="m-3"
                  src="./images/about/vision-icon2.png"
                  width={50}
                  alt="quotation"
                />
                <p
                  className="m-0"
                  style={{
                    width: "140px",
                  }}
                >
                  Partners {"&"} <br /> Co-operations
                </p>
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <img
                  className="m-3"
                  src="./images/about/vision-icon3.png"
                  width={50}
                  alt="quotation"
                />
                <p
                  className="m-0"
                  style={{
                    width: "140px",
                  }}
                >
                  Development {"&"} <br /> Deployment
                </p>
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <img
                  className="m-3"
                  src="./images/about/vision-icon4.png"
                  width={50}
                  alt="quotation"
                />
                <p
                  className="m-0"
                  style={{
                    width: "140px",
                  }}
                >
                  Modern <br /> Technologies
                </p>
              </div>
            </div>
          </Container>
        </Section8>
        <Section9>
          <Container>
            <Row>
              <Col md={6} sm={12} className="p-5">
                <h2>Get In Touch</h2>
                <div className="my-5">
                  <div className="d-flex flex-row justify-content-start align-items-center my-4">
                    <img src="./images/about/map-icon1.png" width={20} alt="" />
                    <p className="m-0 mx-3">
                      #908 - 1112 W Pender st, Vancouver, BC, V6E 2S1
                    </p>
                    {/* <p className="m-0 mx-3">
                    #820 - 1112 W Pender st, Vancouver, BC, V6E 2S1
                  </p> */}
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center my-4">
                    <img src="./images/about/map-icon2.png" width={20} alt="" />
                    <p className="m-0 mx-3">+1 (604) - 789 - 6812</p>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center my-4">
                    <img src="./images/about/map-icon3.png" width={20} alt="" />
                    <p className="m-0 mx-3">info@richlink.ca</p>
                  </div>
                </div>
                <div>
                  <Row>
                    <Col md={5} sm={12} className="my-3 my-md-0">
                      <InputAbout
                        type="text"
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Col>
                    <Col md={7} sm={12}>
                      <InputAbout
                        type="text"
                        placeholder="Email Address"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} sm={12} className="py-4">
                      <Textarea
                        placeholder="Write your message here"
                        onChange={(e) => setMessage(e.target.value)}
                      ></Textarea>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} sm={12}>
                      <SendButton onClick={handleSendMessage}>Send</SendButton>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={6} sm={12} className="p-0">
                {/* <Image src="./images/about/map.png" width="100%" fluid /> */}
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2602.410521497732!2d-123.12333418423714!3d49.287566078345485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54867183e0d6c3e9%3A0x60ecfcb89b8aa4ad!2s1112%20W%20Pender%20St%2C%20Vancouver%2C%20BC%20V6E%202S1!5e0!3m2!1sen!2sca!4v1638309492188!5m2!1sen!2sca"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  loading="lazy"
                ></iframe>
              </Col>
            </Row>
          </Container>
        </Section9>
      </div>
    </Layout>
  );
}

const Section1 = styled.div`
  padding: 10px 0px;
  margin-bottom: 60px;
`;

const Intro = styled.h1`
  font-size: 70px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const Section2 = styled.div`
  padding: 30px 0px;
`;

const ActivityItem = styled.div`
  background-color: #151515;
  padding: 40px;
  border-radius: 5px;
  position: relative;
  p {
    height: 100px;
  }
`;

const Section3 = styled.div`
  padding: 40px 0px;
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
`;

const Input = styled.input`
  border: 0px;
  outline: 0px;
  padding: 20px;
  width: 30%;
  height: 50px;
  border-radius: 5px;
  @media (max-width: 768px) {
    border-radius: 5px;
    width: 80%;
  }
`;

const SubscribeButton = styled.button`
  border: 0px;
  padding: 12px 40px;
  margin: 10px;
  border: 1px solid #131313;
  border-radius: 5px;
  color: #fff;
  background-image: linear-gradient(to right, #4e9bff, #b621fe);
  transition: 0.3s;
  :hover {
    opacity: 0.5;
  }
`;

const Section4 = styled.div`
  padding: 100px 0px;
  .small-title {
    color: #0da8ff;
  }
  .title {
    color: #fff;
    font-size: 64px;
    font-weight: 600;
    margin-bottom: 30px;

    width: fit-content; /* Adjust width as needed */
    /* Apply linear gradient to bottom border */
    border-bottom: 20px solid;
    border-image: linear-gradient(to right, #4e9bff, #b621fe) 1;
    /* Adjust the thickness and gradient colors as needed */
  }
  button {
    border: 0px;
    padding: 12px 40px;
    margin: 10px 0px;
    border-radius: 100px;
    color: #fff;
    background-image: linear-gradient(to right, #4e9bff, #b621fe);
    transition: 0.3s;
    :hover {
      opacity: 0.5;
    }
  }
`;

// const CommentWrapper = styled.div`
//   padding: 50px;
//   padding-top: 90px;
//   border-radius: 5px;
//   height: 80%;
//   background-color: #151515;
//   background-image: url(./images/home/people-bg.png);
//   background-size: 50%;
//   background-repeat: no-repeat;
//   background-position: 50% -25%;
//   @media (max-width: 768px) {
//     background-position: 50% 100%;
//     padding: 80px 30px;
//   }
// `;

const Section7 = styled.div`
  background-color: #151515;
`;

const Section8 = styled.div`
  padding-top: 40px;
  padding-bottom: 80px;
`;

const Section9 = styled.div`
  background-color: black;
`;

const InputAbout = styled.input`
  padding: 15px;
  width: 100%;
  background-color: #fff;
  border: 0px;
  border-radius: 5px;
  outline: 0px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 160px;
  padding: 15px;
  background-color: #fff;
  border: 0px;
  border-radius: 5px;
  outline: 0px;
`;

const SendButton = styled.button`
  width: 100%;
  outline: 0px;
  background-image: linear-gradient(to right, #4e9bff, #b621fe);
  color: #fff;
  padding: 20px;
  border: 0px;
  border-radius: 5px;
  outline: 0px;
`;
