/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Layout from "../components/Layout";
import { Container } from "react-bootstrap";
import styled from "styled-components";

export default function Portfolio() {
  return (
    <Layout>
      <Container className="pb-5">
        <a href="https://apps.apple.com/ca/app/vennchat/id6443394582">
          <Section>
            <div className="item">
              <div className="left">
                <img
                  width={300}
                  height={300}
                  className="my-3"
                  src="https://is1-ssl.mzstatic.com/image/thumb/Purple221/v4/b5/9e/3d/b59e3dc1-0fe4-27bb-2aa9-6f84c6e5e06c/AppIcon-0-1x_U007emarketing-0-0-0-6-0-0-sRGB-85-220-0.png/460x0w.webp"
                />
              </div>
              <div className="right">
                <h3 className="my-5">VennChat</h3>
                <p className="mt-5">
                  VennChat is a dynamic social media platform that connects
                  users, leading to meaningful interactions and relationships.
                  By collaborating with trusted application publishers, VennChat
                  creates abundant opportunities for personal growth, leadership
                  development, and exploration within a fresh and exciting
                  social media landscape.
                </p>
              </div>
            </div>
          </Section>
        </a>

        <a href="https://apps.apple.com/au/app/vhot/id1663976918">
          <Section>
            <div className="item">
              <div className="left">
                <img
                  width={300}
                  height={300}
                  className="my-3"
                  src="https://is1-ssl.mzstatic.com/image/thumb/Purple126/v4/58/fb/a3/58fba3f4-cf13-bcf1-c9c2-7106cd4f74ce/AppIcon-0-0-1x_U007ephone-0-10-0-sRGB-85-220.png/460x0w.webp"
                />
              </div>
              <div className="right">
                <h3 className="my-5">Vhot</h3>
                <p className="mt-5">
                  Vhot is a video community product of Yexc Technologies Inc. We
                  are dedicated to providing a platform where all creators can
                  be creative to record videos and exchange ideas with other
                  creators. It provides users with video viewing, recording,
                  creating posts and sharing. Users can share real-life
                  experiences, interests and skills with a personal perspective
                  and establish connections with other users worldwide. Be here
                  to discover creativity and get inspiration quickly.
                </p>
              </div>
            </div>
          </Section>
        </a>

        <a href="https://www.cryptohero.game/">
          <Section>
            <div className="item">
              <div className="left">
                <img
                  width={300}
                  height={300}
                  className="my-3"
                  src="https://www.cryptohero.game/assets/box.png"
                />
              </div>
              <div className="right">
                <h3 className="my-5">Crypto Hero</h3>
                <p className="mt-5">
                  🕹 An idle game powered by Web3, allowing players to earn
                  rewards at their convenience, from any location.
                </p>
              </div>
            </div>
          </Section>
        </a>

        <a href="https://www.ezcoin.io/">
          <Section>
            <div className="item">
              <div className="left">
                <img
                  width={300}
                  height={340}
                  className="my-3"
                  src="https://www.ezcoin.io/home/phone.png"
                />
              </div>
              <div className="right">
                <h3 className="my-5">EZ Coin</h3>
                <p className="mt-5">
                  🪙 A streamlined, secure, and professional trading platform to
                  let users buy & sell crypto currencies easily.
                </p>
              </div>
            </div>
          </Section>
        </a>
      </Container>
    </Layout>
  );
}

const Section = styled.div`
  background-color: #151515;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 80px;
  /* margin: 60px 0px; */
  border: 1px solid #151515;
  display: inline-block;

  transition: 0.3s;
  :hover {
    background-color: #2121ff;
    color: #fff;
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;

    .left {
      width: 30%;
      padding: 10px;
    }
    .right {
      width: 70%;
      padding: 10px;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    img {
      width: 200px;
      height: 200px;
    }
    .left {
      width: 100%;
    }
    .right {
      width: 100%;
    }
  }
`;
