import React from "react";
import Layout from "../components/Layout";
import { Container } from "react-bootstrap";

export default function Solutions() {
  return (
    <Layout>
      <Container>
        <h1>Solutions</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui, sint
          distinctio iste molestias natus nesciunt iure eum itaque officia
          tempore vitae eveniet esse quos cum amet rem laboriosam nobis ipsum.
        </p>
      </Container>
    </Layout>
  );
}
