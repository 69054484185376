import "../styles/web-nav.scss";

export default function WebNav() {
  return (
    <div>
      <nav className="menu">
        <ol>
          <li className="menu-item">
            <a href="#home">Home</a>
            {/* <Link to="#home">Home</Link> */}
          </li>
          <li className="menu-item">
            <a href="#products">Products</a>
            {/* <Link to="/portfolio">Portfolio</Link> */}
          </li>
          <li className="menu-item">
            <a href="#about">About</a>
            {/* <Link to="/about">About</Link> */}
          </li>
        </ol>
      </nav>
    </div>
  );
}
